<template>
  <div class="kt-container kt-grid__item kt-grid__item--fluid">
    <div
      role="alert"
      v-show="errors.length > 0"
      v-bind:class="{ show: errors.length }"
      class="alert fade alert-danger"
    >
      <div class="alert-text" v-for="(error, i) in errors" :key="i">
        {{ error }}
      </div>
    </div>

    <div
      role="alert"
      v-show="success.length > 0"
      v-bind:class="{ show: success.length }"
      class="alert fade alert-success"
    >
      <div class="alert-text" v-for="(suc, i) in success" :key="i">
        {{ suc }}
      </div>
    </div>

    <div class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Upload Results</h3>
        </div>
      </div>

      <div class="kt-portlet__body">
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="example-input-group-11"
            label-for="example-input-11"
          >
            <b-form-input
              id="example-input-11"
              name="example-input-11"
              v-model="$v.form.requisitonno.$model"
              :state="validateState('requisitonno')"
              aria-describedby="input-11-live-feedback"
              placeholder="Requisition No"
              :readonly="reqno_readonly"
              @blur.native="serachByReqno($v.form.requisitonno.$model)"
            ></b-form-input>

            <b-form-invalid-feedback id="input-11-live-feedback"
              >Requistion No is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group id="example-input-group-0" label-for="example-input-0">
            <b-form-input
              id="example-input-0"
              name="example-input-0"
              v-model="$v.form.invoiceno.$model"
              :state="validateState('invoiceno')"
              aria-describedby="input-0-live-feedback"
              v-bind:readonly="invoiceno_readonly"
              @blur.native="serachByinvoiceno($v.form.invoiceno.$model)"
              placeholder="Invoice No"
            ></b-form-input>

            <b-form-invalid-feedback id="input-0-live-feedback"
              >Invoice No is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-12"
            label-for="example-input-12"
          >
            <b-form-input
              id="example-input-12"
              name="example-input-12"
              readonly
              v-model="$v.form.patientname.$model"
              :state="validateState('patientname')"
              aria-describedby="input-12-live-feedback"
              placeholder="Patient Name"
            ></b-form-input>

            <b-form-invalid-feedback id="input-12-live-feedback"
              >Patient Name is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-15"
            label-for="example-input-15"
          >
            <b-form-input
              id="example-input-15"
              name="example-input-15"
              readonly
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              aria-describedby="input-15-live-feedback"
              placeholder="Mobile No"
            ></b-form-input>
            <b-form-invalid-feedback id="input-15-live-feedback"
              >Mobile No is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-4"
            label
            label-for="example-input-4"
          >
            <b-form-select
              id="example-input-4"
              name="example-input-4"
              aria-describedby="input-4-live-feedback"
              v-model="$v.form.testlist.$model"
              :options="testlist"
              class="form-select"
              :state="validateState('testlist')"
            ></b-form-select>

            <b-form-invalid-feedback id="input-4-live-feedback"
              >Please select a test.</b-form-invalid-feedback
            >
          </b-form-group>

          <!--   <b-form-group id="example-input-group-0" label-for="example-input-0">
            <b-form-input
              id="example-input-0"
              name="example-input-0"
              v-model="$v.form.testname.$model"
              :state="validateState('testname')"
              aria-describedby="input-0-live-feedback"
              placeholder="Test Name"
            ></b-form-input>
            <b-form-invalid-feedback id="input-0-live-feedback"
              >Test Name is required.</b-form-invalid-feedback
            >
          </b-form-group>-->

          <b-form-group
            id="example-input-group-20"
            label-for="example-input-20"
          >
            <b-form-file
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".pdf"
            ></b-form-file>
          </b-form-group>
          <v-col class="text-right">
            <b-button type="submit" id="kt_submit" variant="primary"
              >Submit</b-button
            >
            <b-button class="ml-2" @click="BacktoUserList" variant="danger"
              >Back to List</b-button
            >
          </v-col>

          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
    </div>
  </div>
</template>



<style>
.custom-select:focus {
  box-shadow: none;
  outline: none !important;
}
.custominvalid-feeback {
  width: 100%;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #fd397a !important;
}
.custom-select.is-invalid:focus {
  box-shadow: none;
}
.custom-select.is-valid:focus {
  box-shadow: none;
}
</style>

<script>
import moment from "moment";

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import axios from "axios";
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";
export default {
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      // Remove this dummy login info
      form: {
        id: "",
        requisitonno: "",
        password: "",
        invoiceno: "",
        patientname: "",
        phone: "",
        testlist: null,
        captchaToken: "",
        dateofbirth: "",
        testname: "",
      },
      file1: null,
      Username: "",
      loading: true,
      reqno_readonly: false,
      invoiceno_readonly: false,
      filevalidate: "",
      number: [],
      country: [],
      errors: [],
      success: [],
      finishMounted: false,
      info: null,
      Isphonevalid: null,

      key: "",
      testlist: [{ value: null, text: "Select a Test." }],
    };
  },
  validations: {
    form: {
      invoiceno: {
        required,
        minLength: minLength(3),
      },
      patientname: {
        required,
        minLength: minLength(3),
      },

      requisitonno: {
        required,
        minLength: minLength(4),
      },

      phone: { required },
      testlist: {
        required,
      },
    },
  },
  methods: {
    serachByReqno(value) {
      if (value == "") {
        return;
      }

      this.success = [];
      this.loading = true;
      console.log(value);

      const data = {
        facilityid: JwtService.getFacilityToken().split("~")[1],
        orderid: value,
      };

      this.invoiceno_readonly = true;
      axios.post(API_URL + "getOtherOrderList", data).then((response) => {
        console.log(response.data);

        console.log(response.data.hasError);
        if (response.data.hasError) {
          this.errors = [response.data.message];
          this.invoiceno_readonly = false;
        } else {
          this.errors = [];
         
          this.$v.form.invoiceno.$model = response.data.data[0].invoiceno;
          this.$v.form.patientname.$model = response.data.data[0].Paitentname;
          this.$v.form.phone.$model = response.data.data[0].cellphone;             
          
          this.populateTest(response.data);
           if (this.form.id > 0) {
            this.$v.form.testlist.$model = this.$route.params.resultobj.testid;
          }
          else{this.success = [response.data.message];}
        }
      });
    },
    serachByinvoiceno(value) {
      if (value == "") {
        this.reqno_readonly = false;
        return;
      }
      this.success = [];
      this.loading = true;
      console.log(value);

      const data = {
        facilityid: JwtService.getFacilityToken().split("~")[1],
        invoiceno: value,
      };

      this.reqno_readonly = true;
      axios.post(API_URL + "getOtherOrderList", data).then((response) => {
        console.log(response.data);
        console.log(response.data.hasError);
        if (response.data.hasError) {
          this.errors = [response.data.message];
          this.reqno_readonly = false;
        } else {
          this.errors = [];
          this.$v.form.requisitonno.$model = response.data.data[0].orderid;
          this.$v.form.patientname.$model = response.data.data[0].Paitentname;
          this.$v.form.phone.$model = response.data.data[0].cellphone;
          this.populateTest(response.data);
        }
      });
    },
    populateTest(data) {
      console.log(data.data);
      this.testlist = [];
      this.testlist.push({ value: null, text: "Select a Test." });
      data.data.forEach((value, index) => {
        this.testlist.push({
          value: value.Testid,
          text: value.testcode + " - " + value.testname,
        });
        console.log(index);
      });
    },
    yourValidationMethod: function ({ number, isValid, country }) {
      if (this.$v.form.phone.$model != "") {
        this.Isphonevalid = isValid;
      }

      this.number = number;
      this.country = country;

      return isValid;
    },
    onInput({ number, isValid, country }) {
      this.number = number;
      this.country = country;
      //console.log(number + " " + isValid + " " + country.name);
      this.Isphonevalid = isValid;
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        id: "0",
        requisitonno: null,
        email: null,
        invoiceno: "",
        patientname: "",
        testlist: "",
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      console.log(this.$v.form.testlist.$model);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const invoiceno = this.$v.form.invoiceno.$model;
      const requisitonno = this.$v.form.requisitonno.$model;
      const patientname = this.$v.form.patientname.$model;
      const phone = this.$v.form.phone.$model;
      const selectedtest = this.$v.form.testlist.$model;

      console.log(this.form.id);

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add(
        "kt-spinner",
        "kt-spinner--light",
        "kt-spinner--right",
          
      );

      submitButton.disabled=true;

      let data = new FormData();
      data.append("id", this.form.id);
      data.append("orderId", requisitonno);
      data.append("InvoiceNo", invoiceno);
      data.append("LabNo", requisitonno);
      data.append("PatientName", patientname);
      data.append("ContactNo", phone);
      data.append("testid", selectedtest);
      data.append("result", this.file1);
      data.append("uploadedBy", this.Username);
      var today = new Date();
      data.append("uploadedDate", moment(today).format("YYYY-MM-DD HH:mm:ss"));
      data.append("facilityid", JwtService.getFacilityToken().split("~")[1]);

      var config = {
        method: "post",
        url: API_URL + "menualUploadResult",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          submitButton.classList.remove(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right",
             
          );
          submitButton.disabled=false;
          console.log(response.data);

          if (response.data.hasError) {
            this.errors = [response.data.message];
          } else {
            this.success = [response.data.message];
          }

           if(response.data.id)
           this.form.id = response.data.id;
          // this.$router.push({
          //   name: "admindashboard",
          //   params: { tab: "contact" },
          // })
        })
        .catch(function (error) {
          this.errors = [error];
          console.log(error);
        });
    },
    BacktoUserList() {
      this.resetForm();
      this.$router.push({ name: "admindashboard", params: { tab: "contact" } });
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
  },
  computed: {
    backgroundImage() {
      return "url(' + require('@/assets/media/bg/bg-4.jpg')";
    },
  },
  created() {
    this.key = this.$route.params.key;
    const isAdmin = JwtService.getToken().split("~")[3];

     if(isAdmin == undefined || isAdmin == 0)
     {
       console.log("redirect to login admin");
        this.$router.push({ name: "adminlogin" });
     }
     
  },
  mounted() {
    if (this.$route.params.resultobj != null) {
      this.form.id = this.$route.params.resultobj.id;
      this.$v.form.requisitonno.$model = this.$route.params.resultobj.LabNo;
      this.$v.form.invoiceno.$model = this.$route.params.resultobj.InvoiceNo;
      this.$v.form.patientname.$model = this.$route.params.resultobj.PatientName;
      this.$v.form.phone.$model = this.$route.params.resultobj.ContactNo;
      this.serachByReqno(this.$route.params.resultobj.LabNo);
      //this.$v.form.testname.$model = this.$route.params.resultobj.TestName;

      console.log(this.$route.params.resultobj.Testid);
    }

    var token =     JwtService.getToken();
    console.log(token);
    const UserName = JwtService.getToken().split("~")[5];
    console.log(UserName);
    this.Username = UserName;
  },
};
</script>
